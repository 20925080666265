exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-cennik-tsx": () => import("./../../../src/pages/cennik.tsx" /* webpackChunkName: "component---src-pages-cennik-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-lokalizacja-tsx": () => import("./../../../src/pages/lokalizacja.tsx" /* webpackChunkName: "component---src-pages-lokalizacja-tsx" */),
  "component---src-pages-zalecenia-tsx": () => import("./../../../src/pages/zalecenia.tsx" /* webpackChunkName: "component---src-pages-zalecenia-tsx" */),
  "component---src-templates-oferta-page-template-tsx": () => import("./../../../src/templates/OfertaPageTemplate.tsx" /* webpackChunkName: "component---src-templates-oferta-page-template-tsx" */),
  "component---src-templates-zabieg-page-template-tsx": () => import("./../../../src/templates/ZabiegPageTemplate.tsx" /* webpackChunkName: "component---src-templates-zabieg-page-template-tsx" */)
}

